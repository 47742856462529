import React, {Component} from "react";
// Externals
import PropTypes from "prop-types";
import classNames from "classnames";
// Material helpers
// Material components
import {Input, withStyles} from "@material-ui/core";
// Material icons
import {Search as SearchIcon} from "@material-ui/icons";
// Component styles
import styles from "./styles";
import { debounce } from "underscore";


class SearchInput extends Component {
    state = {
        searchString: ""
    };

    debouncedSearch = debounce((value) => {
        console.log("After",new Date());
        this?.props?.getsearchterm(value)
        this.props.getsearchterm(value);
    }, 500);

    handleFieldChange = (value) => {
        const trimmedValue = value.replace(/\s/g, "");
        
        this.setState({
            searchString: value
        });

        if (trimmedValue.length >= 3 || trimmedValue.length === 0) { 
            console.log("before",new Date());  
            this.debouncedSearch(value);
        }
        
    };

    componentWillUnmount() {
        this.debouncedSearch.cancel();
    }


    render() {
        const {classes, className, onChange, style, ...rest} = this.props;
        const rootClassName = classNames(classes.root, className);
        return (
            <div className={rootClassName} style={style}>
                <SearchIcon className={classes.icon}/>
                <Input
                    {...rest}
                    searchString={this.state.searchString}
                    value={
                         this.state.searchString
                    }
                    className={classes.input}
                    style={{width: "220px"}}
                    id={"search_input"}
                    disableUnderline
                    onChange={event => {
                        this.handleFieldChange(event.target.value);
                    }}
                />
            </div>
        );
    }
}

SearchInput.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object
};

export default withStyles(styles)(SearchInput);
