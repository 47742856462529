import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Slide from "@material-ui/core/Slide";
import {Box, TextField} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogDateRange = props => {
    const [date, setDate] = React.useState({});
    const {open, handleClose, handleDate, isRange} = props;

    const handleDateRange = event => {
        setDate({
            ...date,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = () => {
        handleDate(date);
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Select Date "}
                </DialogTitle>
                <DialogContent>
                    <Box display={"flex"} flexDirection={"row"}>
                        <Box mr={1}>
                            <TextField
                                label={isRange ? "from" : "Select"}
                                fullWidth
                                type={"date"}
                                value={date?.start_date}
                                name={"start_date"}
                                onChange={e => handleDateRange(e)}
                                inputProps={{
                                    min: "2020-01-24",
                                    max: new Date().toISOString().slice(0, 10)
                                }}
                            />
                        </Box>
                        {isRange && <Box>
                            <TextField
                                label={"to"}
                                fullWidth
                                type={"date"}
                                value={date?.end_date}
                                name={"end_date"}
                                onChange={e => handleDateRange(e)}
                                inputProps={{
                                    min: "2020-01-24",
                                    max: new Date().toISOString().slice(0, 10)
                                }}
                            />
                        </Box>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button variant={"contained"} color="primary" onClick={handleSubmit}>
                        set Date
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default DialogDateRange;
