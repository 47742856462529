import React, {Component} from "react";
// import { Link } from 'react-router-dom';
// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";
import Grid from "@material-ui/core/Grid";
import {withSnackbar} from "notistack";
import {LoaderDots} from "@thumbtack/thumbprint-react";

// Material helpers
// Material components
import {
    DialogActions,
    DialogTitle,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Tooltip from "@material-ui/core/Tooltip";

// Shared components
import {Portlet, PortletContent} from "components";

// Component styles
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GoogleMapsReact from "../../../../components/GoogleMaps/google_maps_react";
import NoDatafound from "../../../AllCustomers/components/NoDataInTable";
import {CloseOutlined as CloseButton, CreateOutlined,} from "@material-ui/icons";
import reposWebApi from "../../../../actions/configuration";
import qs from "qs";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import Drawer from "@material-ui/core/Drawer";
import Label from "../../../Dashboard/components/Label";
import FormControl from "@material-ui/core/FormControl";
import {sendLogs} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import secureLocalStorage from "react-secure-storage";

export class UsersTable extends Component {
    state = {
        selectedUsers: [],
        rowsPerPage: 10,
        page: 0,
        open: false,
        latLon: "",
        reg_no: "",
        setEditOpen: false,
        peso: "",
        vehicle_number: "",
        is_more: false,
        refullers_list: [],
        carIq_Dialog: false,
        rpp_status: "",
        refueler_id: "",
        is_activeCariq: false,
        loader: false,
        peso_Received: false,
        cariq_Received: false,
        setUQCOpen: false,
        deliveryDate: "",
        fasRecieved: false,
        is_dialog: false,
        current_fuel_rate: "",
        fuel_density:"",
        refueler_ids:[null],
    };

    handleChangePage = (event, page) => {
        this.props.onPagination(this.props.rowsPerPage, page);
    };

    handleChangeRowsPerPage = (event) => {
        this.props.onPagination(event.target.value, 0);
    };

    openDialog(latLon, vehicle_info) {
        this.setState({
            open: true,
            latLon: latLon,
            reg_no: vehicle_info,
        });
    }

    handleOpenDrawer = (e, refuller) => {
        e.preventDefault();
        this.setState({
            is_more: true,
            refullers_list: refuller,
        });
    };

    openEditDialog(e, id, peso, registration_number) {
        e.preventDefault();
        this.setState({
            setEditOpen: true,
            peso: peso,
            vehicle_number: registration_number,
            refueler_id: id,
        });
    }

    handleAssigneCarIq = (e, active, id) => {
        e.preventDefault();
        this.setState({
            carIq_Dialog: true,
            is_activeCariq: active,
            refueler_id: id,
        });
    };

    handleFieldChange = (value, field) => {
        this.setState({
            ...this.state,
            [field]: value,
        });
    };

    handleClose() {
        this.setState({
            ...this.state,
            open: false,
            setEditOpen: false,
            is_more: false,
            carIq_Dialog: false,
            peso: "",
            vehicle_number: "",
            setUQCOpen: false,
            current_fuel_rate: "",
            fuel_density: ""
        });
    }

    getLogs = (accessToken, mobile, url_endpoint, u_json) => {
        this.props.handleGetLogs(accessToken, mobile, url_endpoint, u_json);
    };

    changeRppStatus = (status, id, peso, registrationName, deliveryDate) => {
        if (status === "DIS" && !this.state.cariq_Received) {
            this.setState({
                ...this.state,
                carIq_Dialog: true,
                is_activeCariq: true,
                rpp_status: status,
                refueler_id: id,
                peso: peso,
                vehicle_number: registrationName,
            });
        } else if (status === "PES" && !this.state.peso_Received) {
            this.setState({
                setEditOpen: true,
                rpp_status: status,
                refueler_id: id,
                peso: peso,
                vehicle_number: registrationName,
            });
        } else if (status === "UQC" && !this.state.peso_Received) {
            this.setState({
                setUQCOpen: true,
                rpp_status: status,
                refueler_id: id,
                est_delivery_date: deliveryDate,
            });
        } else {
            const requestBody = {
                rpp_status: status,
                refueler_id: id,
            };
            reposWebApi
                .post("/refueler/change_rpp_status_lmd", qs.stringify(requestBody), {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            rpp_status: "",
                            refueler_id: "",
                        });
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.mobile,
                            "/refueler/change_rpp_status_lmd",
                            qs.stringify(requestBody)
                        );
                        this.props.enqueueSnackbar("RPP status updated successfully!", {
                            transitionDuration: { enter: 200, exit: 200 },
                            variant: "success",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                        });
                        this.props.reloadData();
                    }
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.mobile,
                            "/refueler/change_rpp_status_lmd",
                            qs.stringify(requestBody)
                        );
                        this.props.enqueueSnackbar("Please fill in all the details!", {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                        });
                    } else if (error.response.status === 404) {
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.mobile,
                            "/refueler/change_rpp_status_lmd",
                            qs.stringify(requestBody)
                        );
                        this.props.enqueueSnackbar("Refueler not found!!", {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                        });
                    } else {
                        this.getLogs(
                            secureLocalStorage.getItem("accessToken"),
                            secureLocalStorage.mobile,
                            "/refueler/change_rpp_status_lmd",
                            qs.stringify(requestBody)
                        );
                        this.props.enqueueSnackbar("something went wrong!", {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                        });
                    }
                    this.setState({
                        rpp_status: "",
                        refueler_id: "",
                    });
                });
        }
    };

    handleCarIqSubmit = (e) => {
        let requestBody = {
            refueler_id: parseInt(this.state.refueler_id),
            registration_number: this.state.registration_number,
        };
        this.setState({
            loader: true,
        });
        reposWebApi
            .post("/refueler/assign_cariq", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                },
            })
            .then((res) => {
                if (res.status === 201 || res.status === 200) {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.mobile,
                        "/refueler/assign_cariq",
                        qs.stringify(requestBody)
                    );
                    this.props.enqueueSnackbar("CarIQ created and binded successufully", {
                        transitionDuration: { enter: 200, exit: 200 },
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "success",
                    });
                    this.setState({
                        loader: false,
                    });
                    this.setState({
                        cariq_Received: true,
                    });
                    this.changeRppStatus(this.state.rpp_status, this.state.refueler_id);
                    this.props.reloadData();
                    this.handleClose();
                }
            })
            .catch((error) => {
                this.setState({
                    loader: false,
                });
                if (error.response.status === 403) {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.mobile,
                        "/refueler/assign_cariq",
                        qs.stringify(requestBody)
                    );
                    this.props.enqueueSnackbar(
                        "CarIQ is already assigned other refueler",
                        {
                            variant: "error",
                        }
                    );
                } else if (error.response.status === 404) {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.mobile,
                        "/refueler/assign_cariq",
                        qs.stringify(requestBody)
                    );
                    this.props.enqueueSnackbar("CarIQ you have entered is not found", {
                        variant: "error",
                    });
                }
            });
    };
    changeActivationStatus = (e, status, id) => {
        e.preventDefault();
        const requestBody = {
            refueler_id: id,
            is_active: status,
        };
        reposWebApi
            .post(
                "/refueler/make_refueler_active_inactive",
                qs.stringify(requestBody),
                {
                    headers: {
                        Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    this.getLogs(
                        secureLocalStorage.getItem("accessToken"),
                        secureLocalStorage.mobile,
                        "/refueler/make_refueler_active_inactive",
                        qs.stringify(requestBody)
                    );
                    this.props.reloadData();
                    this.props.enqueueSnackbar(response.data.data, {
                        transitionDuration: { enter: 200, exit: 200 },
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    this.props.enqueueSnackbar("Something Went Wrong.", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                }
                if (error.response.status === 501) {
                    this.props.enqueueSnackbar(
                        "Cannot activate this RPP because no tracker was assigned to it!",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                        }
                    );
                }
                if (error.response.status === 502) {
                    this.props.enqueueSnackbar(
                        "Cannot activate this RPP because no Driver was assigned to it!",
                        {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                        }
                    );
                }
            });
    };

    handleEdit = (e) => {
        e.preventDefault();
        const requestBody = {
            rpp_id: this.state.refueler_id,
            rpp_name: this.state.vehicle_number.toUpperCase(),
            peso: this.state.peso.toUpperCase(),
        };
        reposWebApi
            .post("refueler/change_rpp_data_lmd", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                },
            })
            .then((response) => {
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.mobile,
                    "refueler/change_rpp_data_lmd",
                    qs.stringify(requestBody)
                );
                if (this.state.rpp_status === "PES") {
                    this.setState({
                        peso_Received: true,
                    });
                    this.changeRppStatus(this.state.rpp_status, this.state.refueler_id);
                    this.handleClose();
                } else {
                    this.handleClose();
                }
                this.handleClose();
                this.props.enqueueSnackbar("RPP data has been successfully changed!", {
                    transitionDuration: { enter: 200, exit: 200 },
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });

                this.props.reloadData();
            })
            .catch((err) => {
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.mobile,
                    "refueler/change_rpp_data_lmd",
                    qs.stringify(requestBody)
                );
                this.props.enqueueSnackbar("Something went wrong :( ", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            });
    };

    handleUpdate = (e) => {
        e.preventDefault();
        const requestBody = {
            refueler_id: this.state.refueler_id,
            rpp_status: this.state.rpp_status,
            est_delivery_date: this.state.deliveryDate,
        };
        reposWebApi
            .post("refueler/change_rpp_status_lmd", qs.stringify(requestBody), {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                },
            })
            .then((response) => {
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.mobile,
                    "refueler/change_rpp_status_lmd",
                    qs.stringify(requestBody)
                );
                if (this.state.rpp_status === "UQC") {
                    this.setState({
                        fasRecieved: true,
                    });
                    this.changeRppStatus(this.state.rpp_status, this.state.refueler_id);
                    this.handleClose();
                } else {
                    this.handleClose();
                }
                this.handleClose();
                this.props.enqueueSnackbar("RPP data has been successfully changed!", {
                    transitionDuration: { enter: 200, exit: 200 },
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });

                this.props.reloadData();
            })
            .catch((err) => {
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.mobile,
                    "refueler/change_rpp_status_lmd",
                    qs.stringify(requestBody)
                );
                this.props.enqueueSnackbar("Something went wrong :( ", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            });
    };

    handleDialogOpen = (e, id) => {
        this.setState({
            is_dialog: true,
            refueler_ids: [id],
            current_fuel_rate: "",
            fuel_density: "",
        });
    };

    handleDialogClose = () => {
        this.setState({
            is_dialog: false,
            current_fuel_rate: "",
            fuel_density: "",
            refueler_ids: [null],
        });
    };
    

    handleChange = (field, value) => {
        // Convert to appropriate type for numeric fields
        const processedValue = field === 'refueler_ids' 
            ? [value] 
            : (field === 'current_fuel_rate' || field === 'fuel_density' 
                ? value.trim() 
                : value);
    
        this.setState({
            [field]: processedValue,
        });
    };

    handleRateUpdate = (e) => {
        e.preventDefault();
        
        const { refueler_ids, current_fuel_rate, fuel_density } = this.state;
        
        // Validate inputs
        if (!current_fuel_rate || !fuel_density) {
            this.props.enqueueSnackbar("Please fill all required fields", {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
            return;
        }
    
        const requestBody = {
            refueler_ids: refueler_ids,
            current_fuel_rate: current_fuel_rate,
            fuel_density: fuel_density,
        };
    
        reposWebApi
            .post("partner/refueler_rate_density_update", requestBody, {
                headers: {
                    Authorization: "Bearer " + secureLocalStorage.getItem("accessToken"),
                },
            })
            .then((response) => {
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.mobile,
                    "partner/refueler_rate_density_update",
                    qs.stringify(requestBody)
                );
                
                // Close dialog on successful API call
                this.setState({
                    is_dialog: false,
                    current_fuel_rate: "",
                    fuel_density: "",
                    refueler_ids: [null],
                });
    
                this.props.enqueueSnackbar("Rate Updated successfully!", {
                    transitionDuration: { enter: 200, exit: 200 },
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
                this.props.reloadData();
            })
            .catch((err) => {
                this.getLogs(
                    secureLocalStorage.getItem("accessToken"),
                    secureLocalStorage.mobile,
                    "partner/refueler_rate_density_update",
                    qs.stringify(requestBody)
                );
                this.props.enqueueSnackbar("Something went wrong :(", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            });
    };
    

    render() {
        const { classes, className } = this.props;
        const rootClassName = classNames(classes.root, className);
        if (this.props.isLoading) {
            return (
                <div>
                    <CircularProgress className={classes.progress} />
                </div>
            );
        } else
            return (
                <Portlet className={rootClassName}>
                    <PortletContent noPadding>
                        <PerfectScrollbar>
                            <TableContainer style={{ maxHeight: 640 }}>
                                <Table id={"refuellerTable"}>
                                    <TableHead>
                                        <TableRow
                                            style={{
                                                backdropFilter: "blur(5px)",
                                                background: "rgb(255 255 255 / 36%)",
                                                backgroundBlendMode: "exclusion",
                                                boxShadow: "2px 3px 7px 0px rgb(0 0 0 / 37%)",
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 10,
                                            }}
                                        >
                                            <TableCell align="left">Edit</TableCell>
                                            <TableCell align="left">Id</TableCell>
                                            <TableCell align="left">Vehicle Number</TableCell>
                                            <TableCell align="left">Partner</TableCell>
                                            <TableCell align="left">Partner Contact</TableCell>
                                            <TableCell align="left">RPP Status</TableCell>
                                            <TableCell align="left">CarIQ Status</TableCell>
                                            <TableCell align="left">RPP Stage</TableCell>
                                            <TableCell align="center">More</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {this.props.noDataFound ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={"13"}>
                                                    <NoDatafound />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {this.props.allRefuellerData?.map((refueller) => (
                                                <TableRow
                                                    className={classes.tableRow}
                                                    hover
                                                    key={refueller.vehicle_registration_number}
                                                    id={"table_row_allRefuellers"}
                                                >
                                                    {/*vehicle number*/}
                                                    <TableCell className={classes.tableCell}>
                                                        <label htmlFor="icon-button-file">
                                                            <IconButton
                                                                onClick={(e) =>
                                                                    this.openEditDialog(
                                                                        e,
                                                                        refueller.id,
                                                                        refueller.peso,
                                                                        refueller.vehicle_registration_number
                                                                    )
                                                                }
                                                                aria-label="upload picture"
                                                                component="span"
                                                                disabled={secureLocalStorage.getItem("access_type") === false}
                                                            >
                                                                <CreateOutlined />
                                                            </IconButton>
                                                        </label>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {refueller.id}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {refueller.vehicle_registration_number}
                                                    </TableCell>
                                                    {/*partner*/}
                                                    <TableCell className={classes.tableCell}>
                                                        {refueller.partner_name}
                                                    </TableCell>
                                                    {/*partner contact*/}
                                                    <TableCell className={classes.tableCell}>
                                                        {refueller.partner_contact_number}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Tooltip
                                                            title={
                                                                refueller.rpp_status === "PES"
                                                                    ? ""
                                                                    : "Peso certification required"
                                                            }
                                                            aria-label="add"
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        disabled={
                                                                            secureLocalStorage.getItem("access_type") === false
                                                                        }
                                                                        checked={refueller.is_active}
                                                                        name="rppActivationSwitch"
                                                                        classes={{
                                                                            root: classes.root,
                                                                            switchBase: classes.switchBase,
                                                                            thumb: classes.thumb,
                                                                            track: classes.track,
                                                                            checked: classes.checked,
                                                                        }}
                                                                        onChange={(e) =>
                                                                            this.changeActivationStatus(
                                                                                e,
                                                                                !refueller.is_active,
                                                                                refueller.id
                                                                            )
                                                                        }
                                                                        inputProps={{
                                                                            "aria-label": "secondary checkbox",
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <Tooltip
                                                            title={
                                                                refueller.is_cariq_activated
                                                                    ? " " +
                                                                    "If you want to perform the following actions, please contact the TECH TEAM\n" +
                                                                    "                                            for further instructions!"
                                                                    : "Car IQ needed only when the RPP stage is on 'Dispatched'"
                                                            }
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        disabled={
                                                                            !(
                                                                                refueller.rpp_status === "DIS" &&
                                                                                secureLocalStorage.getItem("access_type") === false
                                                                            )
                                                                        }
                                                                        checked={refueller.is_cariq_activated}
                                                                        name="carIQSwitch"
                                                                        classes={{
                                                                            root: classes.root,
                                                                            switchBase: classes.switchBase,
                                                                            thumb: classes.thumb,
                                                                            track: classes.track,
                                                                            checked: classes.checked,
                                                                        }}
                                                                        onChange={(e) =>
                                                                            this.handleAssigneCarIq(
                                                                                e,
                                                                                !refueller.is_cariq_activated,
                                                                                refueller.id
                                                                            )
                                                                        }
                                                                        inputProps={{
                                                                            "aria-label": "secondary checkbox",
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {refueller.rpp_status === "PES" ? (
                                                            <InputLabel>PESO & Licensing</InputLabel>
                                                        ) : (
                                                            <FormControl
                                                                disabled={secureLocalStorage.getItem("access_type") === false}
                                                            >
                                                                {refueller.rpp_status === "REC" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        RPP Booking Done
                                                                    </InputLabel>
                                                                ) : refueller.rpp_status === "TMD" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        Finance Approved
                                                                    </InputLabel>
                                                                ) : refueller.rpp_status === "TBM" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        Finance Disbursed
                                                                    </InputLabel>
                                                                ) : refueller.rpp_status === "FAS" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        Chassis Received
                                                                    </InputLabel>
                                                                ) : refueller.rpp_status === "UQC" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        Manufacturing of RPP
                                                                    </InputLabel>
                                                                ) : refueller.rpp_status === "DIS" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        PDI & Dispatched
                                                                    </InputLabel>
                                                                ) : refueller.rpp_status === "RRC" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        RTO Registration
                                                                    </InputLabel>
                                                                ) : refueller.rpp_status === "PES" ? (
                                                                    <InputLabel
                                                                        style={{ color: "blue" }}
                                                                        id="refueler_id"
                                                                    >
                                                                        PESO & Licensing
                                                                    </InputLabel>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                <Select
                                                                    variant={"standard"}
                                                                    style={{ width: "190px", margin: "10px 0" }}
                                                                    labelId={refueller.rpp_status}
                                                                    id="status"
                                                                    value={""}
                                                                    onChange={(e) =>
                                                                        this.changeRppStatus(
                                                                            e.target.value,
                                                                            refueller.id,
                                                                            refueller.peso,
                                                                            refueller.vehicle_registration_number
                                                                        )
                                                                    }
                                                                    label={refueller.rpp_status}
                                                                    color={"secondary"}
                                                                >
                                                                    {refueller.rpp_status === "REC" ? (
                                                                        <MenuItem value={"TMD"}>
                                                                            Finance Approved
                                                                        </MenuItem>
                                                                    ) : refueller.rpp_status === "TMD" ? (
                                                                        <MenuItem value={"TBM"}>
                                                                            Finance Disbursed
                                                                        </MenuItem>
                                                                    ) : refueller.rpp_status === "TBM" ? (
                                                                        <MenuItem value={"FAS"}>
                                                                            Chassis Received
                                                                        </MenuItem>
                                                                    ) : refueller.rpp_status === "FAS" ? (
                                                                        <MenuItem value={"UQC"}>
                                                                            Manufacturing of RPP
                                                                        </MenuItem>
                                                                    ) : refueller.rpp_status === "UQC" ? (
                                                                        <MenuItem value={"DIS"}>
                                                                            PDI & Dispatched
                                                                        </MenuItem>
                                                                    ) : refueller.rpp_status === "DIS" ? (
                                                                        <MenuItem value={"RRC"}>
                                                                            RTO Registration
                                                                        </MenuItem>
                                                                    ) : refueller.rpp_status === "RRC" ? (
                                                                        <MenuItem value={"PES"}>
                                                                            PESO & Licensing
                                                                        </MenuItem>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            size={"medium"}
                                                            variant="extended"
                                                            style={{ textTransform: "capitalize" }}
                                                            aria-label="delete"
                                                            className={classes.fab}
                                                            onClick={(event) => {
                                                                this.handleOpenDrawer(event, refueller);
                                                            }}
                                                        >
                                                            <MoreHorizIcon className={classes.extendedIcon} />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </PerfectScrollbar>

                        <Dialog
                            fullWidth
                            onClose={this.handleClose.bind(this)}
                            aria-labelledby="simple-dialog-title"
                            open={this.state.setEditOpen}
                        >
                            {/*<DialogTitle style={{background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}} id="simple-dialog-title">*/}
                            <DialogTitle
                                style={{
                                    background:
                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                }}
                                id="simple-dialog-title"
                            >
                                <div style={{ margin: "15px 0px" }}>
                                    <Typography style={{ color: "#fff" }} variant="h4">
                                        Edit this RPP?
                                    </Typography>
                                    <Typography style={{ color: "#fff" }} variant={"subtitle1"}>
                                        Are you sure you want to edit the given details ?
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div style={{ margin: "10px 0px" }}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                label="PESO License Number"
                                                id="outlined-size-small"
                                                variant="outlined"
                                                name={"peso"}
                                                // value={this.state.refuelerForEdit.peso}
                                                defaultValue={this.state.peso}
                                                onChange={(e) =>
                                                    this.handleFieldChange(e.target.value, e.target.name)
                                                }
                                                size="medium"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                label="Vehicle Reg. Number"
                                                id="outlined-size-small"
                                                name={"vehicle_number"}
                                                // value={this.state.refuelerForEdit.vehicle_registration_number}
                                                defaultValue={this.state.vehicle_number}
                                                onChange={(e) =>
                                                    this.handleFieldChange(e.target.value, e.target.name)
                                                }
                                                variant="outlined"
                                                size="medium"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="text"
                                    color="default"
                                    onClick={this.handleClose.bind(this)}
                                >
                                    Cancel
                                </Button>
                                {this.state.peso === "" || this.state.vehicle_number === "" ? (
                                    ""
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={(e) => this.handleEdit(e)}
                                        style={{
                                            background:
                                                "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                            color: "#fff",
                                        }}
                                    >
                                        Update
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth
                            onClose={this.handleClose.bind(this)}
                            aria-labelledby="simple-dialog-title"
                            open={this.state.setUQCOpen}
                        >
                            <DialogTitle
                                style={{
                                    background:
                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                }}
                                id="simple-dialog-title"
                            >
                                <div style={{ margin: "15px 0px" }}>
                                    <Typography style={{ color: "#fff" }} variant="h4">
                                        Please Update the Estimated delivery date
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <TextField
                                    size={"small"}
                                    name="deliveryDate"
                                    label="Estimate Delivery Date"
                                    type="date"
                                    fullWidth
                                    onChange={(e) =>
                                        this.handleFieldChange(e.target.value, e.target.name)
                                    }
                                    style={{ margin: "10px" }}
                                    className={classes.textField}
                                    variant={"outlined"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: new Date().toISOString().split("T")[0], // Set minimum date to today
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="text"
                                    color="default"
                                    onClick={this.handleClose.bind(this)}
                                >
                                    Cancel
                                </Button>
                                {this.state.deliveryDate === "" ? (
                                    ""
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={(e) => this.handleUpdate(e)}
                                        style={{
                                            background:
                                                "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                            color: "#fff",
                                        }}
                                    >
                                        Update
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>

                        {/*more field*/}
                        <Drawer
                            anchor={"right"}
                            open={this.state.is_more}
                            onClose={this.handleClose.bind(this)}
                        >
                            <div className={classes.drawerHeading}></div>
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={this.handleClose.bind(this)}>
                                    <CloseButton />
                                </IconButton>
                            </div>
                            <Table>
                                <TableBody>
                                    <TableRow align={"center"}>
                                        <TableCell style={{ width: "100%" }}>
                                            <Typography className={classes.secondaryHeading}>
                                                <InfoTwoToneIcon
                                                    style={{
                                                        position: "relative",
                                                        bottom: -5,
                                                        marginRight: 5,
                                                    }}
                                                />
                                                More Information
                                            </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>PESO License</TableCell>
                                        <TableCell>
                                            {this.state.refullers_list.peso === null
                                                ? "Non"
                                                : this.state.refullers_list.peso}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Operator Contact</TableCell>
                                        <TableCell>
                                            {this.state.refullers_list.operator_contact_number}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Last Location</TableCell>
                                        <TableCell>
                                            <Button
                                                size={"small"}
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={() =>
                                                    this.openDialog(
                                                        this?.state?.refullers_list.last_latlong,
                                                        this?.state?.refullers_list
                                                            ?.vehicle_registration_number
                                                    )
                                                }
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Last Updated</TableCell>
                                        <TableCell>
                                            {this.state.refullers_list.updated_at}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stock Data</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {this.state.refullers_list.stock_data === 0
                                                ? "No Data"
                                                : this.state.refullers_list.stock_data + " L"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">CarIQ Status</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Label
                                                variant={"outlined"}
                                                color={
                                                    this.state.refullers_list.is_cariq_activated
                                                        ? "green"
                                                        : "red"
                                                }
                                            >
                                                {this.state.refullers_list.is_cariq_activated
                                                    ? "Active"
                                                    : "Deactive"}
                                            </Label>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Current Rate</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            &#8377; {this.state.refullers_list.current_rate}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Current Density</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {this.state.refullers_list.current_density}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>RPP Order Type</TableCell>
                                        <TableCell>
                                            {this.state.refullers_list.self_pickup_rpp
                                                ? "Self Pickup"
                                                : "Door Step Delivery"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Estimate Delivery Date</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {this.state.refullers_list.est_delivery_date}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Authorization</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {this.state.refullers_list.dmo_auth}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Update Rate</TableCell>
                                        <TableCell>
                                            <Button
                                                size={"small"}
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={(e) =>
                                                    this.handleDialogOpen(
                                                        e,
                                                        this.state.refullers_list.id
                                                    )
                                                }
                                            >
                                                Update
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Drawer>

                        <Dialog
                            fullWidth
                            maxWidth={"xs"}
                            onClose={this.handleClose.bind(this)}
                            open={this.state.carIq_Dialog}
                            aria-labelledby="simple-dialog-title"
                        >
                            <DialogTitle
                                style={{
                                    background:
                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                }}
                                id="cariq-dialog"
                            >
                                {!this.state.is_activeCariq ? (
                                    <div style={{ margin: "15px 0px" }}>
                                        <Typography style={{ color: "#fff" }} variant="h4">
                                            Alert!
                                        </Typography>
                                    </div>
                                ) : (
                                    <div style={{ margin: "15px 0px" }}>
                                        <Typography style={{ color: "#fff" }} variant="h4">
                                            Required Information
                                        </Typography>
                                        <Typography style={{ color: "#fff" }} variant={"subtitle1"}>
                                            Please fill in this information before changing the RPP
                                            status to Dispatch.
                                        </Typography>
                                    </div>
                                )}
                            </DialogTitle>

                            <DialogContent>
                                {!this.state.is_activeCariq ? (
                                    <div>
                                        <Typography variant={"subtitle1"}>
                                            If you want to perform the following actions, please
                                            contact the TECH TEAM for further instructions!
                                        </Typography>
                                    </div>
                                ) : (
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        style={{ marginTop: 10 }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div style={{ margin: "10px 0px" }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Cariq Registration Number"
                                                        margin="dense"
                                                        required
                                                        value={this.state.registration_number}
                                                        id={"registrationNumber"}
                                                        onChange={(event) =>
                                                            this.handleFieldChange(
                                                                event.target.value,
                                                                "registration_number"
                                                            )
                                                        }
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                )}
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.handleClose.bind(this)}
                                >
                                    Cancel
                                </Button>
                                {!this.state.is_activeCariq ? (
                                    ""
                                ) : this.state.loader ? (
                                    <div>
                                        <LoaderDots size={"medium"} />
                                    </div>
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={(e) => this.handleCarIqSubmit(e)}
                                        style={{
                                            background:
                                                "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                            color: "#fff",
                                        }}
                                    >
                                        Submit
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose.bind(this)}
                            fullWidth={true}
                        >
                            <DialogContent style={{ padding: 0 }}>
                                <div style={{ padding: 0 }}>
                                    <GoogleMapsReact
                                        google={this.props.google}
                                        marker={this.state.latLon}
                                        markerInfo={this.state.reg_no}
                                        zoom={15}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions style={{ alignItems: "center" }}>
                                <Button
                                    size={"small"}
                                    variant={"contained"}
                                    color="primary"
                                    onClick={this.handleClose.bind(this)}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth
                            aria-labelledby="draggable-dialog-title"
                            open={this.state.is_dialog}
                            onClose={this.handleDialogClose} 
                            >
                            <DialogTitle
                                style={{
                                    background:
                                        "linear-gradient(45deg, #0767db 30%, #4E95E7 90%)",
                                }}
                            >
                                <div style={{ margin: "15px 0px" }}>
                                    <Typography
                                        style={{ color: "#fff" }}
                                        variant="h4"
                                        align={"center"}
                                    >
                                        Update Fuel Rate
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <Typography
                                    gutterBottom
                                    style={{ margin: "10px 0" }}
                                >
                                    Enter Diesel Rate *
                                    <TextField
                                        className={classes.textField}
                                        margin="dense"
                                        fullWidth
                                        type="number"
                                        id={"current_fuel_rate"}
                                        value={this.state.current_fuel_rate}
                                        onChange={(event) =>
                                            this.handleChange("current_fuel_rate", event.target.value)
                                        }
                                        variant="outlined"
                                        placeholder={"in Rs."}
                                    />
                                </Typography>
                                <Typography
                                    gutterBottom
                                    style={{ margin: "10px 0" }}
                                >
                                    Enter Density *
                                    <TextField
                                        className={classes.textField}
                                        margin="dense"
                                        fullWidth
                                        type="number"
                                        id={"fuel_density"}
                                        value={this.state.fuel_density}
                                        onChange={(event) =>
                                            this.handleChange("fuel_density", event.target.value)
                                        }
                                        variant="outlined"
                                        placeholder={"in Kg/m3."}
                                    />
                                </Typography>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    autoFocus
                                    onClose={this.handleDialogClose} 
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                    <Button
                                        onClick={(e) =>
                                            this.handleRateUpdate(
                                                e
                                            )
                                        }
                                        color="primary"
                                    >
                                        Continue
                                    </Button>
                            </DialogActions>
                        </Dialog>

                        {this.props.noDataFound ? (
                            ""
                        ) : (
                            <TablePagination
                                backIconButtonProps={{
                                    "aria-label": "Previous Page",
                                }}
                                component="div"
                                count={this.props.dataCount}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page",
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                page={this.props.page}
                                rowsPerPage={this.props.rowsPerPage}
                                rowsPerPageOptions={[10, 20, 50, 100]}
                            />
                        )}
                    </PortletContent>
                </Portlet>
            );
    }
}

UsersTable.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    onShowDetails: PropTypes.func,
    users: PropTypes.array.isRequired,
};

UsersTable.defaultProps = {
    users: [],
    onSelect: () => {
    },
    onShowDetails: () => {
    },
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.isLoggedIn,
        isLoginPending: state.isLoginPending,
        isLoading: state.isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetLogs: (accessToken, mobile, url_endpoint, u_json) => {
            dispatch(sendLogs(accessToken, mobile, url_endpoint, u_json));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(withSnackbar(UsersTable)));
